import {query_IsPartOfProject} from "~/graphql/user";

export default defineNuxtRouteMiddleware((to, from) => {
    const config = useRuntimeConfig()
    const cookie = useCookie('auth')
    console.log("Cookie auth");
    console.log(cookie.value);

    let projectID = to.query.p;
    //console.log(to);

    if(cookie.value === undefined)
    {
        if (to.path !== '/project') {
            if (to.path !== '/login') return navigateTo('/login');
        }
    }else{
        if (to.path !== '/') {
            if(projectID){
                isPartOfProject(projectID).then(function(result){
                    if (!result.IsPartOfProject) {
                        if (to.path !== '/') return navigateTo('/');
                    }
                })
            }
        }
    }
})

const isPartOfProject = async (projectID) => {
    let {data} = await useNuxtApp().$apollo.clients.user.query({
        query: query_IsPartOfProject,
        variables: {
            projectID: projectID,
        },
        fetchPolicy: 'no-cache'
    });
    //console.log(data);
    return data;
}